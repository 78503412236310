<template>
  <b-row class="justify-content-center">
    <b-col cols="12" class="text-center">
      <div class="text-uppercase i-text-small i-primary pt-4 clients-header">
        {{ $t('home.clients.title') }}
      </div>
    </b-col>
    <b-col cols="12">
      <b-row class="justify-content-center mb-2">
        <b-col
            v-for="(company, index) in company_logos"
            :key="'company'+index"
            cols="auto"
            class="px-1 px-sm-4 px-md-5 mx-lg-2 mx-xl-4"
        >
          <b-button
              variant="link"
              :href="company.link"
              target="_blank"
              classs="logo-button"
          >
            <b-img-lazy
                :src="company.src"
                :alt="company.alt"
                class="logo"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      company_logos: [
        {
          src: "https://ik.imagekit.io/isotronic/homepage/company_logos/muellermueller-frame_l583YBOxd.svg?updatedAt=1629788382674",
          alt: this.$t('home.clients.alt1'),
          link: "https://www.mmjoh-glas.com/de/"
        },
        {
          src: "https://ik.imagekit.io/isotronic/homepage/company_logos/rofra-frame_BvvPdVoUZ0V.png?updatedAt=1629788383093",
          alt: this.$t('home.clients.alt2'),
          link: "http://www.rofra.de/"
        },
        {
          src: "https://ik.imagekit.io/isotronic/homepage/company_logos/bormioli-frame_9aSRaUmL6w.svg?updatedAt=1629788382842",
          alt: this.$t('home.clients.alt3'),
          link: "https://www.bormiolipharma.com/en/"
        },
        {
          src: "https://ik.imagekit.io/isotronic/homepage/company_logos/nipro-frame_NwyifLevvA.svg?updatedAt=1629788382740",
          alt: this.$t('home.clients.alt4'),
          link: "https://www.nipro-group.com/en"
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.clients-header
  letter-spacing: 0.2em

.logo-button
  &:focus
    outline: none !important
    box-shadow: none !important

.logo
  width: 50px
  height: 50px

  &:hover
    cursor: pointer
    display: block
    opacity: 0.5

  @media screen and (min-width: 450px)
    width: 70px
    height: 70px
    margin-left: 10px
    margin-right: 10px
  @media screen and (min-width: $sm)
    margin-left: 0
    margin-right: 0
    height: 110px
    width: 110px
  @media screen and (min-width: $md)
    height: 80px
    width: 80px
  @media screen and (min-width: $lg)
    height: 110px
    width: 110px
</style>
