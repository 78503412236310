<template>
  <router-link :to="$i18nRoute({ name: routerLink})" tag="span">
    <b-card class="product-card mb-3 mb-sm-4 p-2 px-sm-2 px-lg-5 p-xl-4">
      <b-row class="w-100 mx-0 px-0 card-content"
             :class="isTablet ? 'justify-content-left' : 'justify-content-center'">
        <b-col cols="auto" class="px-0 pt-0 my-auto image-part" :order="leftAlign ? '1' : '0'" order-sm="0">
          <b-img-lazy fluid :src="src"
                      class="product-icon ml-sm-0"
                      :class="leftAlign ? 'mr-3 mr-sm-0' : ''"
                      :alt="alt"/>
        </b-col>
        <b-col class="my-auto text-part"
               cols="auto"
               :class="[isMobile || isTablet ? 'text-left' : 'text-center', leftAlign ? 'pr-0' : '']">
          <div class="i-font-header i-primary text-uppercase mt-2 mt-sm-0 product-header">
            <slot name="header"></slot>
          </div>
          <div class="i-secondary  i-font-text-light product-sub-header my-2 mb-sm-0">
            <slot name="subheader"></slot>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </router-link>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    src: String,
    alt: String,
    routerLink: String,
    leftAlign: { // align img to left or right side
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState({
      isTablet: state => state.isTablet,
      isMobile: state => state.isMobile
    })
  }
}
</script>

<style lang="sass" scoped>
.card-content
  @media screen and (min-width: $sm)
    align-content: center!important
    height: 100%!important

.image-part
  width: 70px
  @media screen and (min-width: $sm)
    width: 100%

.text-part
  width: calc(100% - 70px)
  @media screen and (min-width: $sm)
    width: 100%
  ::v-deep(p)
    margin-bottom: 0!important

.product-card
  width: 100%
  border-radius: 30px
  border: none
  box-shadow: 0 0 24px 0 #E3272614
  @media screen and (min-width: $sm)
    border-radius: 50px
    height: 280px
    width: 340px
  @media screen and (min-width: $lg)
    height: 300px
    width: 420px
  @media screen and (min-width: $xl)
    width: 483.33px
    height: 340px

  &:hover
    box-shadow: 1px 2px 3.3px rgba(201, 35, 34, 0.061), 3.4px 6.7px 11.2px rgba(201, 35, 34, 0.089), 15px 30px 50px rgba(201, 35, 34, 0.15)
    cursor: pointer

.product-card /deep/ .card-body
  padding: 0 !important

.product-icon
  height: 70px
  @media screen and (min-width: $sm)
    height: 100px
    width: 100px
  @media screen and (min-width: $xl)
    height: 150px
    width: 150px

.product-icon-mobile
  height: 110px

.product-header
  font-size: 22px
  line-height: 1.1
  @media screen and (min-width: $sm)
    line-height: normal
    font-size: 26px
  @media screen and (min-width: $xl)
    font-size: 30px

.product-sub-header
  font-size: 14px
  @media screen and (min-width: $xl)
    font-size: 18px
</style>
