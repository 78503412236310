<template>
  <b-card class="review-card p-2 i-font-text-light">
    <div class="h-100">
      <div class="mb-4 flex-grow-1">
        <slot name="quote"></slot>
      </div>
      <b-row class="name-row">
        <b-col cols="7" sm="9">
          <div class="i-primary i-font-text-bold">
            <slot name="name"></slot>
          </div>
          <div class="i-font-text-light">
            <slot name="position"></slot>
          </div>
        </b-col>
        <!--          <b-col class="" cols="3">-->
        <!--            <b-img-lazy :alt="alt" :src="src" class="logo"></b-img-lazy>-->
        <!--          </b-col>-->
      </b-row>
    </div>
    <b-img-lazy :alt="alt"
                :src="src"
                :class="'logo-' + company"
                class="logo"/>
  </b-card>

</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    company: String
  }
}
</script>

<style lang="sass" scoped>

.review-card
  box-shadow: 0px 0px 24px 0px #E3272614 !important
  border-radius: 30px !important
  height: 380px !important
  line-height: 1.3
  @media screen and (min-width: 475px)
    height: 280px !important

.logo
  max-height: 75px
  max-width: 120px
  position: absolute

.logo-worldwide
  right: 1.5rem
  bottom: 3rem
  @media screen and (min-width: 475px)
    bottom: 2.3rem

.logo-meuller
  right: 1.8rem
  bottom: 1rem

.logo-rofra
  right: 0.5rem
  max-height: 90px !important
  bottom: 1rem

.logo-thuringer
  max-height: 50px !important
  right: 1.5rem
  bottom: 2.1rem

.name-row
  height: 90px
  position: absolute
  bottom: 0
  width: 100%
  @media screen and (min-width: 475px)
    height: 75px
</style>
