<template>
  <b-row class="justify-content-center i-outer-padding i-vertical-no-photo range-of-products">
    <b-col cols="12" class="complete-title-section">
      <h2 class="text-uppercase mb-2">
        {{ $t('home.solution.header') }}
      </h2>
    </b-col>
    <b-col cols="12" md="8" lg="6" xl="4" class="complete-title-section i-header-section-margin">
      <div class="i-secondary i-text">
        {{ $t('home.solution.subheader') }}
      </div>
    </b-col>
    <div class="w-100"/>
    <b-col class="text-center p-0" cols="12">
      <b-row class="solutions-row justify-content-center mx-auto">
        <b-col
            v-for="(solution, index) in solutions"
            :key="solution.header + index"
            cols="auto"
            class="text-center solution-square"
        >
          <complete-product-square
              :alt="solution.alt"
              :src="solution.src"
              :router-link="solution.link"
              :left-align="index % 2!==1"
          >
            <template #header>
              {{ solution.header }}
            </template>
            <template #subheader>
              <i18n :path="solution.subheader" tag="div">
                <template v-if="solution.product" v-slot:product>
              <span class="i-font-text-bold">
                {{ solution.product }}
              </span>
                </template>
              </i18n>
            </template>
          </complete-product-square>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from "vuex";
import CompleteProductSquare from "@/components/home/CompleteProductSquare";

export default {
  components: {
    CompleteProductSquare
  },
  computed: {
    ...mapState({
      isTablet: state => state.isTablet,
      isMobile: state => state.isMobile,
      isDesktop: state => state.isDesktop
    })
  },
  data() {
    return {
      solutions: [
        {
          header: this.$t('home.solution.solutions.solution1.title'),
          subheader: 'home.solution.solutions.solution1.subtitle',
          product: this.$t('home.solution.solutions.solution1.product'),
          alt: this.$t('home.solution.solutions.solution1.product'),
          src: 'https://ik.imagekit.io/isotronic/homepage/end-to-end/icon-2_BD0WoBsr7Ad.svg?updatedAt=1629980287485',
          link: 'Central Overview',
        },
        {
          header: this.$t('home.solution.solutions.solution2.title'),
          subheader: 'home.solution.solutions.solution2.subtitle',
          product: this.$t('home.solution.solutions.solution2.product'),
          alt: this.$t('home.solution.solutions.solution2.product'),
          src: 'https://ik.imagekit.io/isotronic/homepage/end-to-end/icon-3_t4Xg-BBEzO.svg?updatedAt=1629980287717',
          link: 'Geometric Defects',
        },
        {
          header: this.$t('home.solution.solutions.solution3.title'),
          subheader: 'home.solution.solutions.solution3.subtitle',
          product: this.$t('home.solution.solutions.solution3.product'),
          alt: this.$t('home.solution.solutions.solution3.product'),
          src: 'https://ik.imagekit.io/isotronic/homepage/end-to-end/icon-4_44ZPgZlbOQ.svg?updatedAt=1629980287902',
          link: 'Mechanical Handling',
        },
        {
          header: this.$t('home.solution.solutions.solution4.title'),
          subheader: 'home.solution.solutions.solution4.subtitle',
          product: this.$t('home.solution.solutions.solution4.product'),
          alt: this.$t('home.solution.solutions.solution4.product'),
          src: 'https://ik.imagekit.io/isotronic/homepage/end-to-end/icon_EAGC_73E5.svg?updatedAt=1629980287299',
        },
        {
          header: this.$t('home.solution.solutions.solution5.title'),
          subheader: 'home.solution.solutions.solution5.subtitle',
          src: 'https://ik.imagekit.io/isotronic/homepage/end-to-end/icon-1_XEvAq9GJq.svg?updatedAt=1629980287318',
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.complete-title-section
  text-align: left
  @media screen and (min-width: $sm)
    text-align: center

.range-of-products
  background-color: $light-background

.solution-square
  @media screen and (min-width: 768px) and (max-width: 769px)
    padding-left: 10px
    padding-right: 10px

.solutions-row
  @media screen and (min-width: $lg)
    max-width: 1700px
  @media screen and (min-width: $xl)
    max-width: 1920px

.product-square
  &.left-img
    float: none

  &.right-img
    float: none

  @media screen and (min-width: $sm)
    &.left-img
      float: right !important
    &.right-img
      float: left !important
  @media screen and (min-width: $lg)
    &.left-img
      float: none !important
    &.right-img
      float: none !important
</style>
