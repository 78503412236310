<template>
  <div class="customer-reviews i-border">
    <b-container fluid class="i-skeleton i-vertical-no-photo">
      <h2 class="i-small text-left text-sm-center text-uppercase mb-4">
        {{ $t('home.reviews.title') }}
      </h2>
      <b-row class="justify-content-center card-carousel">
        <b-col cols="12" sm="9" xl="10">
          <carousel
              :perPageCustom="[[1920, 3], [0,1]]"
              class="carousel"
              paginationActiveColor="#E32726"
              :paginationSize=12
              :paginationPadding=7
              paginationColor="#B8B8BA">
            <slide
                v-for="(review, idx) in reviews"
                :key="`review-${idx}`"
                class="p-2 p-sm-3">
              <review-card :src="review.picture" :alt="review.alt" :company="review.company">
                <template #quote>
                  {{ review.quote }}
                </template>
                <template #name>
                  {{ review.name }}
                </template>
                <template #position>
                  {{ review.position }}
                </template>
              </review-card>
            </slide>
          </carousel>
        </b-col>
      </b-row>
      <b-row class="card-grid justify-content-center">
        <b-col v-for="(review, idx) in reviews"
               :key="'review-grid' + idx"
               cols="5" lg="4"
               class="m-3 p-0"
        >
          <review-card :src="review.picture" :alt="review.alt" :company="review.company">
            <template #quote>
              {{ review.quote }}
            </template>
            <template #name>
              {{ review.name }}
            </template>
            <template #position>
              {{ review.position }}
            </template>
          </review-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import ReviewCard from '@/components/home/ReviewCard'

export default {
  components: {
    ReviewCard,
    Carousel,
    Slide
  },
  data() {
    return {
      carousel: {
        dots: true,
        slidesPerRow: 1,
        slidesToShow: 2,
        rows: 2,
        infinite: true,
        speed: 500
      },
      reviews: [
        {
          quote: this.$t('home.reviews.review1.quote'),
          name: this.$t('home.reviews.review1.name'),
          position: this.$t('home.reviews.review1.position'),
          alt: this.$t('home.reviews.review1.alt'),
          company: "meuller",
          picture: "https://ik.imagekit.io/isotronic/homepage/company_logos/muellermueller-frame_l583YBOxd.svg?updatedAt=1629788382674"
        },
        {
          quote: this.$t('home.reviews.review2.quote'),
          name: this.$t('home.reviews.review2.name'),
          position: this.$t('home.reviews.review2.position'),
          alt: this.$t('home.reviews.review2.alt'),
          company: "rofra",
          picture: "https://ik.imagekit.io/isotronic/homepage/company_logos/rofra-frame_BvvPdVoUZ0V.png?updatedAt=1629788383093"
        },
        {
          quote: this.$t('home.reviews.review3.quote'),
          name: this.$t('home.reviews.review3.name'),
          position: this.$t('home.reviews.review3.position'),
          alt: this.$t('home.reviews.review3.alt'),
          company: "worldwide",
          picture: "https://ik.imagekit.io/isotronic/homepage/company_logos/worldwide_izFACujMUQJ.png?updatedAt=1635420606968"
        },
        {
          quote: this.$t('home.reviews.review4.quote'),
          name: this.$t('home.reviews.review4.name'),
          position: this.$t('home.reviews.review4.position'),
          alt: this.$t('home.reviews.review4.alt'),
          company: "thuringer",
          picture: "https://ik.imagekit.io/isotronic/homepage/company_logos/thuringer_LwAwD47Va.png?updatedAt=1634911346572"
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.customer-reviews
  background-color: $light-background

.card-carousel
  @media screen and (min-width: $md) and (max-width: $xl - 1px)
    display: none

.card-grid
  @media screen and (max-width: $md - 1px)
    display: none
  @media screen and (min-width: $xl)
    display: none

</style>

<style lang="sass">
.VueCarousel-slide
  touch-action: pan-x !important

.VueCarousel-inner
  transition: transform 0s ease 0ms !important
  @media screen and (min-width: $sm)
    transition: transform .5s ease 0ms !important

.VueCarousel-slide
  opacity: 1 !important

.VueCarousel-slide-active
  transition: all 0.1s !important
  opacity: 1 !important

</style>
