<template>
  <div class="i-border hero-background">
    <div class="banner">
      <b-row class="banner-header">
        <b-col cols="12" sm="8" md="7" lg="6">
          <div class="banner-text i-outer-padding mt-3">
            <h1 class="i-large i-font-header text-uppercase mb-4">
              {{ $t('home.hero.header') }}
            </h1>
            <div class="i-secondary i-sub-header mb-4">
              {{ $t('home.hero.subheader') }}
            </div>
            <callout-button link="Contact">
              {{ $t('home.hero.callout') }}
            </callout-button>
          </div>
        </b-col>
      </b-row>
      <picture>
        <source media="(max-width: 767px)"
                srcset="https://ik.imagekit.io/isotronic/homepage/hero/mobile-2x_5-Uu9B3KntQ.png?updatedAt=1635420417078">
        <source media="(min-width: 768px) and (max-width: 991px)"
                srcset="https://ik.imagekit.io/isotronic/homepage/hero/768-2x_wMeg6w2de.png?updatedAt=1635420418196">
        <source media="(min-width: 992px) and (max-width: 1439px)"
                srcset="https://ik.imagekit.io/isotronic/homepage/hero/992-2x_dtyNlKvJ9N.png?updatedAt=1635420418060">
        <source media="(min-width: 1440px) and (max-width: 1919px)"
                srcset="https://ik.imagekit.io/isotronic/homepage/hero/1440-2x_mqBF31MZ23.png?updatedAt=1635420418064">
        <source media="(min-width: 1920px)"
                srcset="https://ik.imagekit.io/isotronic/homepage/hero/1920-2x_b22560iZCB.png?updatedAt=1635420418113">
        <img
            class="w-100 banner-img"
            src="https://ik.imagekit.io/isotronic/homepage/hero/992-2x_dtyNlKvJ9N.png?updatedAt=1635420418060"
            :alt="$t('home.images.alt1')">
      </picture>
    </div>
  </div>
</template>

<script>
import CalloutButton from "@/components/utility/CalloutButton"

export default {
  components: {
    CalloutButton
  },
  props: {
    imagePath: String
  }
}
</script>

<style lang="sass" scoped>
.hero-background
  background: linear-gradient(180deg, rgba(100, 101, 104, 0) 0%, rgba(100, 101, 104, 0.05) 100%)

.banner
  position: relative
  @media screen and (min-width: $xl)
    width: 1920px !important
    margin-left: auto
    margin-right: auto

.banner-header
  position: relative
  @media screen and (min-width: $sm)
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0

.banner-text
  position: relative
  top: 0
  transform: translateY(-0%)

  @media screen and (min-width: $sm)
    top: 5%
    transform: translateY(-5%)
  @media screen and (min-width: $md)
    padding-right: 0 !important
    top: 30%
    transform: translateY(-30%)
  @media screen and (min-width: $xl)
    top: 50%
    transform: translateY(-50%)

.banner-img
  max-height: calc(95vh - 72px)
  min-width: 100%
  height: 100%
  object-fit: cover
  object-position: right bottom
</style>
