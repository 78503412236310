<template>
  <page-skeleton :products="false">
    <Hero/>
    <Clients class="i-border"/>
    <section-half-image
        :alt="$t('home.images.alt2')"
        image-path="https://ik.imagekit.io/isotronic/homepage/vial_with_red_backlight_edited_RMtgC1hORwN1.jpg?updatedAt=1637924002324"
    >
      <template #header>
        {{ $t('home.section2.header') }}
      </template>
      <template #content>
        <i18n path="home.section2.text" tag="p">
          <template v-slot:cosmetic>
            <i-link link="Cosmetic Defects">
              {{ $t('home.section2.cosmetic') }}
            </i-link>
          </template>
          <template v-slot:geometric>
            <i-link link="Geometric Defects">
              {{ $t('home.section2.geometric') }}
            </i-link>
          </template>
        </i18n>
        <ul>
          <li>
            <i18n path="home.section2.offer1" tag="p">
              <template v-slot:checker>
                  <span class="i-primary i-font-text-bold">
                    {{ $t('home.section2.checker') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li>
            <i18n path="home.section2.offer2" tag="p">
              <template v-slot:handler>
                  <span class="i-primary i-font-text-bold">
                    {{ $t('home.section2.handler') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li>
            <i18n path="home.section2.offer3" tag="p">
              <template v-slot:central>
                  <span class="i-primary i-font-text-bold">
                    {{ $t('home.section2.central') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
      </template>
    </section-half-image>
    <range-of-products class="i-border"/>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/homepage/complete_system_-USUDiLij9.jpg?updatedAt=1635420192711"
        :alt="$t('home.images.alt3')"
        align="bottom"
    />
    <complete-solution class="i-border"/>
    <section-half-image
        align="bottom"
        photo-right-align
        button-link="Contact"
        :alt="$t('home.images.alt4')"
        image-path="https://ik.imagekit.io/isotronic/homepage/30-years_W6OH12-oQyI.png?updatedAt=1635420236953"
    >
      <template #header>
        {{ $t('home.section5.header') }}
      </template>
      <template #content>
        <i18n path="home.section5.text" tag="p">
          <template v-slot:years>
              <span class="i-font-text-bold">
                {{ $t('home.section5.years') }}
              </span>
          </template>
          <template v-slot:systems>
              <span class="i-font-text-bold">
                {{ $t('home.section5.systems') }}
              </span>
          </template>
          <template v-slot:continents>
              <span class="i-font-text-bold">
                {{ $t('home.section5.continents') }}
              </span>
          </template>
        </i18n>
      </template>
      <template #callout>
        {{ $t('home.section5.callout') }}
      </template>
    </section-half-image>
    <customer-reviews />
    <in-the-media />
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import iLink from "@/components/utility/SiteLink";
import Hero from "@/components/home/HeroBanner"
import Clients from "@/components/home/ClientLogos"
import RangeOfProducts from "@/components/home/RangeOfProducts"
import CompleteSolution from "@/components/home/CompleteSolution";
import FullWidthImage from "@/components/utility/FullWidthImage";
import CustomerReviews from "@/components/home/CustomerReviews"
import InTheMedia from "@/components/home/InTheMedia"

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    RangeOfProducts,
    iLink,
    Hero,
    Clients,
    CompleteSolution,
    FullWidthImage,
    CustomerReviews,
    InTheMedia
  },
  data() {
    return {
      selected: this.$i18n.locale,
      options: [
        {value: 'en', text: 'English'},
        {value: 'de', text: 'German'},
      ],
    }
  },
  metaInfo() {
    return {
      title: this.$t('home.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('home.meta.description')}
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  color: $primary-color

li
  &::marker
    color: $primary-color !important
    font-size: 1.3rem
</style>
