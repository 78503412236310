<template>
  <b-container fluid class="i-skeleton i-vertical-no-photo">
    <h2 class="i-small text-left text-sm-center text-uppercase mb-4">
      {{ $t('home.media.header') }}
    </h2>
    <b-row class="card-grid justify-content-center">
      <b-col v-for="(article, idx) in articles"
             :key="'article-grid' + idx"
             cols="12" md="4" xl="3"
             class="p-0"
             :class="idx === 0 ? 'first-border' : (idx === 2 ? 'last-border' : 'middle-border')"
      >
        <media-card :src="article.picture" :alt="article.alt" :link="article.url">
          <template #title>
            {{ article.title }}
          </template>
          <template #source>
            {{ article.source }}
          </template>
          <template #date>
            {{ article.date }}
          </template>
        </media-card>
        <hr v-if="idx === 0 || idx === 1" class="horizontal-rule my-1 my-sm-0">
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MediaCard from '@/components/home/MediaCard'

export default {
  components: {
    MediaCard
  },
  data() {
    return {
      carousel: {
        dots: true,
        slidesPerRow: 1,
        slidesToShow: 2,
        rows: 2,
        infinite: true,
        speed: 500
      },
      articles: [
        {
          title: this.$t('home.media.article1.title'),
          source: this.$t('home.media.article1.source'),
          date: "26/02/2021",
          url: "https://en.ids-imaging.com/kb-article/vial-check.html",
          alt: this.$t('home.media.article1.alt'),
          picture: "https://ik.imagekit.io/isotronic/homepage/media/ids_cover_Z9dzSe0ad?updatedAt=1637168130953"
        },
        {
          title: this.$t('home.media.article2.title'),
          source: this.$t('home.media.article2.source'),
          date: "21/04/2021",
          url: "https://www.vision-systems.com/factory/manufacturing/article/14201768/glass-vial-manufacturers-vision-system-allows-100-inspection",
          alt: this.$t('home.media.article2.alt'),
          picture: "https://ik.imagekit.io/isotronic/homepage/media/vision_systems_cover_Stkvwlvfcy?updatedAt=1637163176026"
        },
        {
          title: this.$t('home.media.article3.title'),
          source: this.$t('home.media.article3.source'),
          date: "08/06/2021",
          url: "https://www.imveurope.com/feature/vial-statistics",
          alt: this.$t('home.media.article3.alt'),
          picture: "https://ik.imagekit.io/isotronic/homepage/media/imve_cover_U5bKuJ703s.jpg?updatedAt=1637163675906"
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.review-carousel
  display: none
  @media screen and (min-width: $md)
    display: none

.horizontal-rule
  border-top: 0.5px solid $primary-color
  margin-left: 15px
  margin-right: 15px
  @media screen and (min-width: $sm)
    margin-left: 30px
    margin-right: 30px
  @media screen and (min-width: $md)
    display: none

.first-border
  //border-bottom: 0.5px solid $primary-color
  @media screen and (min-width: $md)
    border-bottom: 0
    border-right: 0.5px solid $primary-color

.middle-border
  //border-bottom: 0.5px solid $primary-color
  @media screen and (min-width: $md)
    border-bottom: 0

.last-border
  @media screen and (min-width: $md)
    border-left: 0.5px solid $primary-color

</style>

