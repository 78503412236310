<template>
  <b-card class="media-card i-font-text-light">
    <b-row class="h-100">
      <b-col cols="6" md="12" order="1" order-sm="0" class="image-column">
        <b-img-lazy :alt="alt"
                    :src="src"
                    class="article-image"/>
      </b-col>
      <b-col cols="6" md="12" class="my-auto">
        <div class="article-link-div mb-2">
          <b-link
              class="i-primary i-font-header text-uppercase article-link"
              :href="link"
              target="_blank">
            <slot name="title"></slot>
          </b-link>
        </div>
        <div class="article-source">
          <div class="i-font-text-light i-text-small">
            <slot name="source"></slot>
          </div>
          <div class="i-font-text-light i-text-small">
            <slot name="date"></slot>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    link: String
  }
}
</script>

<style lang="sass" scoped>
.i-font-header
  font-size: 16px
  //line-height: 100%!important
  @media screen and (min-width: $sm)
    font-size: 20px
  @media screen and (min-width: $xl)
    font-size: 28px

.article-link-div
  line-height: 100%
  @media screen and (min-width: $xl)
    margin-top: 10px
    line-height: 120%

.article-source
  line-height: 100%
  @media screen and (min-width: $xl)
    line-height: 120%

.article-link
  &:hover
    color: $primary-color
.media-card
  height: 220px
  width: inherit
  border: none !important
  overflow: hidden
  @media screen and (min-width: $sm)
    height: 180px
  @media screen and (min-width: $md)
    height: 337px
    width: inherit

.media-card /deep/ .card-body
  padding: 1rem
  @media screen and (min-width: $sm)
    padding: 30px

.image-column
  height: 100%
  @media screen and (min-width: $md)
    height: 55%

.article-image
  height: 100%
  width: 100%
  display: block
  object-fit: cover
  object-position: center

</style>
