<template>
  <router-link :to="$i18nRoute({ name: routerLink})" tag="span">
    <b-card class="product-card mb-3 mb-sm-4">
      <b-row class="w-100 product-row mx-0 px-0 justify-content-center justify-content-sm-left justify-content-md-center">
        <b-col cols="3" sm="12" class="px-0">
          <b-img-lazy fluid :src="src" class="product-icon ml-3 ml-sm-0" :alt="alt"/>
        </b-col>
        <b-col cols="9" sm="12" class="my-auto text-left text-sm-center product-column">
          <div class="i-font-header i-primary text-uppercase mt-sm-3 product-header">
            <slot name="header"></slot>
          </div>
          <div class="i-secondary i-font-text-light product-sub-header mt-1">
            <slot name="subheader"></slot>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </router-link>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
    routerLink: String
  }
}
</script>
<style lang="sass" scoped>
.product-column
  padding-left: 1rem
  @media screen and (min-width: 375px)
    padding-left: 0.75rem

.product-row
  height: 100%
  @media screen and (min-width: $sm)
    height: auto
.product-card
  height: 140px
  width: 100%
  border-radius: 30px
  border: none
  box-shadow: 0px 0px 24px 0px #E3272614
  @media screen and (min-width: $sm)
    border-radius: 50px
    height: 311px
    width: 311px
  @media screen and (min-width: $xl)
    height: 389px
    width: 389px

  &:hover
    box-shadow: 1px 2px 3.3px rgba(201, 35, 34, 0.061), 3.4px 6.7px 11.2px rgba(201, 35, 34, 0.089), 15px 30px 50px rgba(201, 35, 34, 0.15)
    cursor: pointer

.product-card /deep/ .card-body
  padding: 0 !important

.product-icon
  height: 110px
  margin-top: 15px
  @media screen and (min-width: $sm)
    height: 180px
    margin-top: 20px
  @media screen and (min-width: $xl)
    margin-top: 46px
    height: 195px

.product-icon-mobile
  height: 110px

.product-header
  font-size: 24px
  line-height: 1.1
  @media screen and (min-width: $sm)
    line-height: normal
    font-size: 26px
  @media screen and (min-width: $xl)
    font-size: 30px

.product-sub-header
  font-size: 14px
  @media screen and (min-width: $xl)
    font-size: 18px
</style>
