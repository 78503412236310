<template>
  <b-row class="justify-content-center i-outer-padding i-vertical-no-photo range-of-products">
    <b-col cols="12" class="products-title-section i-header-section-margin">
      <h2 class="text-uppercase mb-2">
        {{$t('home.products.header')}}
      </h2>
      <div class="i-secondary i-text">
        {{$t('home.products.subheader')}}
        <div class="w-100 mt-2 mt-sm-0"></div>
        {{$t('home.products.subheader2')}}
      </div>
    </b-col>
    <b-col
        v-for="(product, index) in products"
        :key="product.header + index"
        cols="12" sm="6" lg="auto"
        class="text-center"
    >
      <range-product-square
          :alt="product.alt"
          :src="isTablet || isMobile ? product.src2 : product.src"
          :router-link="product.link"
          class="product-square"
          :class="index % 2 === 0 ? 'left-img' : 'right-img'"
      >
        <template #header>
          {{product.header}}
        </template>
        <template #subheader>
          {{product.subheader}}
        </template>
      </range-product-square>
    </b-col>
  </b-row>
</template>

<script>
import RangeProductSquare from "@/components/home/RangeProductSquare";
import {mapState} from "vuex";

export default {
  components: {
    RangeProductSquare
  },
  computed: {
    ...mapState({
      isTablet: state => state.isTablet,
      isMobile: state => state.isMobile
    })
  },
  data() {
    return {
      products: [
        {
          header: this.$t('home.products.types.product1.header'),
          subheader: this.$t('home.products.types.product1.subheader'),
          alt: this.$t('home.products.types.product1.alt'),
          src: 'https://ik.imagekit.io/isotronic/homepage/products/vials_yYVKoVkpnQ.png?updatedAt=1635420829506',
          src2: 'https://ik.imagekit.io/isotronic/homepage/products/vials_1_mXhN25P5q1s.png?updatedAt=1635420829397',
          link: 'Vials',
        },
        {
          header: this.$t('home.products.types.product3.header'),
          subheader: this.$t('home.products.types.product3.subheader'),
          alt: this.$t('home.products.types.product3.alt'),
          src: 'https://ik.imagekit.io/isotronic/homepage/products/syringes_Ysx8TEFew.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665578884715',
          src2: 'https://ik.imagekit.io/isotronic/homepage/products/syringes_1_1JKv3mAVrdB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665578911769',
          link: 'Syringes',
        },
        {
          header: this.$t('home.products.types.product2.header'),
          subheader: this.$t('home.products.types.product2.subheader'),
          alt: this.$t('home.products.types.product2.alt'),
          src: 'https://ik.imagekit.io/isotronic/homepage/products/ampoules_hm31qvuTd4.png?updatedAt=1637058666078',
          src2: 'https://ik.imagekit.io/isotronic/homepage/products/ampoules_1_-Ths0h55Q.png?updatedAt=1635420896019',
          link: 'Ampoules',
        },
        {
          header: this.$t('home.products.types.product4.header'),
          subheader: this.$t('home.products.types.product4.subheader'),
          alt: this.$t('home.products.types.product4.alt'),
          src: 'https://ik.imagekit.io/isotronic/homepage/products/custom_wgC4_dqrpG.png?updatedAt=1635421016472',
          src2: 'https://ik.imagekit.io/isotronic/homepage/products/custom_1_MNXC196UMI7.png?updatedAt=1635421016445',
          link: 'Custom',
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.products-title-section
  text-align: left
  @media screen and (min-width: $sm)
    text-align: center

.range-of-products
  background-color: $light-background

.product-square
  &.left-img
    float: none
  &.right-img
    float: none
  @media screen and (min-width: $sm)
    &.left-img
      float: right!important
    &.right-img
      float: left!important
  @media screen and (min-width: $lg)
    &.left-img
      float: none!important
    &.right-img
      float: none!important
</style>
